import parse, { HTMLReactParserOptions } from 'html-react-parser'
import { isEmpty } from 'lodash'
import { NextPage, NextPageContext } from 'next/types'

import { GET_CMS_PAGE } from '@graphql/queries/getCmsPage'

import HomePage from '@components/HomePage'

interface HomePageContext extends NextPageContext {
  apollo: any
  reduxStore: any
}

const Home: NextPage = ({ cmsPage }: any) => {
  console.log(cmsPage.content)
  const options: HTMLReactParserOptions = {
    replace: (node: any) => {
      if (node.name === 'img') {
        node.attribs.src = node.attribs['data-src']
      }

      if (node.name === 'a') {
        const target: string = 'https://magento.sweetv.com/'
        const match: string = node.attribs.href

        if (match.indexOf(target) > -1) {
          node.attribs.href = match.replace(target, process.env.PWA_URL)
        }
      }
    }
  }

  return (
    <>
      <HomePage>
        {!isEmpty(cmsPage) && (
          <div className={`cms__page ${cmsPage.identifier}`}>
            {parse(
              cmsPage.content.replace(
                /<div data-content-type="html" data-appearance="default" data-element="main">|<\/div>|&gt;|&lt;|&amp;/g,
                (match) => {
                  return (
                    {
                      '<div data-content-type="html" data-appearance="default" data-element="main">':
                        '',
                      '</div>': '',
                      '&gt;': '>',
                      '&lt;': '<',
                      '&amp;': '&'
                    }[match] || ''
                  )
                }
              ),
              options
            )}
          </div>
        )}
      </HomePage>
    </>
  )
}

Home.getInitialProps = async ({ apollo, reduxStore }: HomePageContext) => {
  const state = reduxStore.getState()
  const identifier: string = state.app.storeConfig?.cms_home_page ?? ''
  const isMobile: boolean = state.app.isMobile ?? false
  const pageIdentifier: string = isMobile ? `${identifier}_m` : identifier
  const websiteName: string = state.app.storeConfig?.website_name ?? ''

  if (!identifier) return { cmsPage: {} }
  const { data } = await apollo.query({
    query: GET_CMS_PAGE,
    variables: {
      identifier: pageIdentifier
    }
  })

  const cmsPage = data?.cmsPage ?? {}

  return {
    cmsPage,
    websiteName
  }
}

export default Home
