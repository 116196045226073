import { WebsiteConf } from '@config/website'
import { LazyScroll } from '@headless/ui'
import { Helmet } from 'react-helmet'

import CmsBanner from './CmsBanner'
import CmsProduct from './CmsProduct'
import { StyledHomePage } from './styled'

const HomePage = ({ children }: any) => {
  const { isCustomized } = WebsiteConf
  return isCustomized ? (
    <StyledHomePage>
      <Helmet>
        <meta
          name="title"
          content="Norbu - Pearl Necklaces, Earrings & Jewelry"
        />
        <meta
          name="keywords"
          content="Pearl Necklaces, Pearl Earrings, Pearl Jewelry"
        />
        <meta
          name="description"
          content="Top-Grade Pearls. Honest Prices. sExplore our newest collection of pear jewelry,such as pearl necklace, earrings, pendants and bracelets. Plus, enjoy free shipping on every order!"
        />
        <title>Norbu - Pearl Necklaces, Earrings & Jewelry</title>
      </Helmet>
      <CmsBanner />
      <div className="container">
        <LazyScroll>
          <CmsProduct />
        </LazyScroll>
        {children}
        {/* <LazyScroll>
          <CmsGallery />
        </LazyScroll> */}
      </div>
    </StyledHomePage>
  ) : (
    <>{children}</>
  )
}

export default HomePage
